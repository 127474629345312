<script setup>
import RoleForm from "@/components/roles/RoleForm.vue";
import RolesDataTable from "@/components/roles/RolesDataTable.vue";
import { onMounted, ref } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
const $loading = useLoading(loaderData);

const errors = ref([]);
const headers = ref([
  {
    title: "Role",
    key: "role",
    sortable: false,
  },
  {
    title: "Order",
    key: "order",
    sortable: false,
  },
  {
    title: "Permissions",
    key: "permissions",
    sortable: false,
  },
  {
    title: "Users",
    key: "users",
    sortable: false,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);

const openModal = ref(false);
const deleteDialog = ref(false);
const isCanSeeCreateBtn = ref(false);
const modeModal = ref("create");

const filterInfo = ref({});
const editedItem = ref({});

const roles = ref([]);
const permissions = ref([]);
const groupedPermissions = ref({});

onMounted(async () => {
  canSeeCreateBtn();
  getRoles({ page: 1, perPage: 10 });
});

const getPermissionsList = (search_query = "") => {
  axios
    .get(`/permissions`)
    .then((response) => {
      if (response.status == "success") {
        permissions.value = response.data.permissions
      }
    })
    .catch((error) => {})
    .finally(() => {
    });
};

const getRoles = async (payload = null) => {
  let url = `/roles?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        roles.value = response.data.roles;
        permissions.value = response.data.permissions;
        groupedPermissions.value = response.data.groupedPermissions;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getRoles(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getRoles(filterInfo.value);
};

// Page changed from pagination
const refreshRoles = async () => {
  getRoles(filterInfo.value);
};
const formatName = function (name) {
  return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};
const editItem = async (item) => {
  let editedItemValue = {
    ...item, // Copy all properties from the original item
    permissions: item.permissions.map(obj => obj.id), // Modify the permissions property
    name: formatName(item.name) // Modify the name property
  };
  editedItem.value = editedItemValue;
  modeModal.value = "Update";
  openModal.value = true;
};

const deleteItem = (item) => {
  let editedItemValue = {
    ...item, // Copy all properties from the original item
    permissions: item.permissions.map(obj => obj.id), // Modify the permissions property
    name: formatName(item.name) // Modify the name property
  };
  editedItem.value = editedItemValue;
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/roles/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        // refresh the users list via API
        getRoles(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const canSeeCreateBtn = async () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create Role")
  );
  if (hasPermission ) {
    isCanSeeCreateBtn.value = true;
  } else {
    isCanSeeCreateBtn.value = false;
  }
};

// expose errors and getRoles
defineExpose({
  errors,
  getRoles,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important;">
      <!-- Users Table Section -->
      <RolesDataTable
        :headers="headers"
        :table-data="roles"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @isDialogVisible="openCreateModel"
        @editItem="editItem"
        @deleteItem="deleteItem"
      />
    </VCardText>
  </VCard>

  <!-- Modals Below -->

  <!-- User Edit/Create Modal -->
  <RoleForm
    v-model:isDialogVisible="openModal"
    :role-data="editedItem"
    @refreshRoles="refreshRoles"
    :mode="modeModal"
    :permissions="permissions"
    :grouped-permissions="groupedPermissions"
  />
  <!-- User Delete Confirmation Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />
</template>
