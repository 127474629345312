import _definePage_default_0 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/[...error].vue?definePage&vue'
import _definePage_default_3 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/accept-invitation.vue?definePage&vue'
import _definePage_default_4 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/account-settings/[tab].vue?definePage&vue'
import _definePage_default_5 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/activity-logs.vue?definePage&vue'
import _definePage_default_6 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/auth/password-reset/forgot-password.vue?definePage&vue'
import _definePage_default_7 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/auth/password-reset/update-password.vue?definePage&vue'
import _definePage_default_8 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/auth/password-reset/verify-otp.vue?definePage&vue'
import _definePage_default_9 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/backup_contacts.vue?definePage&vue'
import _definePage_default_10 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/[id].vue?definePage&vue'
import _definePage_default_11 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/create.vue?definePage&vue'
import _definePage_default_12 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/list.vue?definePage&vue'
import _definePage_default_13 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/view/[id].vue?definePage&vue'
import _definePage_default_14 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/certifications/index.vue?definePage&vue'
import _definePage_default_15 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/[id].vue?definePage&vue'
import _definePage_default_16 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/create.vue?definePage&vue'
import _definePage_default_17 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/details-[id].vue?definePage&vue'
import _definePage_default_18 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/list.vue?definePage&vue'
import _definePage_default_19 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/dashboard.vue?definePage&vue'
import _definePage_default_20 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/assignment_rules.vue?definePage&vue'
import _definePage_default_21 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/forms.vue?definePage&vue'
import _definePage_default_22 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/list.vue?definePage&vue'
import _definePage_default_23 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/ticket/[id].vue?definePage&vue'
import _definePage_default_24 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/tickets.vue?definePage&vue'
import _definePage_default_25 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/households.vue?definePage&vue'
import _definePage_default_26 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/insurance-license-types/index.vue?definePage&vue'
import _definePage_default_27 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/[id].vue?definePage&vue'
import _definePage_default_28 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/create.vue?definePage&vue'
import _definePage_default_29 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/list/index.vue?definePage&vue'
import _definePage_default_30 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/view/[id].vue?definePage&vue'
import _definePage_default_31 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/license-types/index.vue?definePage&vue'
import _definePage_default_32 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/login.vue?definePage&vue'
import _definePage_default_33 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/[id].vue?definePage&vue'
import _definePage_default_34 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/create.vue?definePage&vue'
import _definePage_default_35 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/list/index.vue?definePage&vue'
import _definePage_default_36 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/view/[id].vue?definePage&vue'
import _definePage_default_37 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations-deprecated.vue?definePage&vue'
import _definePage_default_38 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/representatives/list/index.vue?definePage&vue'
import _definePage_default_39 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/roles-management/list/index.vue?definePage&vue'
import _definePage_default_40 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/system-settings/[tab].vue?definePage&vue'
import _definePage_default_41 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/teams/list/index.vue?definePage&vue'
import _definePage_default_42 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/teams/view/[id].vue?definePage&vue'
import _definePage_default_43 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-invites/list/index.vue?definePage&vue'
import _definePage_default_44 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/[id].vue?definePage&vue'
import _definePage_default_45 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/create.vue?definePage&vue'
import _definePage_default_46 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/list/index.vue?definePage&vue'
import _definePage_default_47 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/view/[id].vue?definePage&vue'
import _definePage_default_48 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/[id].vue?definePage&vue'
import _definePage_default_49 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/create.vue?definePage&vue'
import _definePage_default_50 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/details-[id].vue?definePage&vue'
import _definePage_default_51 from '/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/list.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/accept-invitation',
    name: 'accept-invitation',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/accept-invitation.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  {
    path: '/account-settings',
    /* internal name: 'account-settings' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':tab',
        name: 'account-settings-tab',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/account-settings/[tab].vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/activity-logs',
    name: 'activity-logs',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/activity-logs.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  {
    path: '/auth',
    /* internal name: 'auth' */
    /* no component */
    children: [
      {
        path: 'password-reset',
        /* internal name: 'auth-password-reset' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'forgot-password',
            name: 'auth-password-reset-forgot-password',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/auth/password-reset/forgot-password.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
  _mergeRouteRecord(
          {
            path: 'update-password',
            name: 'auth-password-reset-update-password',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/auth/password-reset/update-password.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'verify-otp',
            name: 'auth-password-reset-verify-otp',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/auth/password-reset/verify-otp.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/backup_contacts',
    name: 'backup-contacts',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/backup_contacts.vue'),
    /* no children */
  },
  _definePage_default_9
  ),
  {
    path: '/branches',
    /* internal name: 'branches' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'branches-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/[id].vue'),
        /* no children */
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'branches-create',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/create.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'list',
        name: 'branches-list',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/list.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
      {
        path: 'view',
        /* internal name: 'branches-view' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'branches-view-id',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/branches/view/[id].vue'),
            /* no children */
          },
  _definePage_default_13
  )
        ],
      }
    ],
  },
  {
    path: '/certifications',
    /* internal name: 'certifications' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'certifications',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/certifications/index.vue'),
        /* no children */
      },
  _definePage_default_14
  )
    ],
  },
  {
    path: '/contacts',
    /* internal name: 'contacts' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'contacts-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/[id].vue'),
        /* no children */
      },
  _definePage_default_15
  ),
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'contacts-create',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/create.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'details-:id',
        name: 'contacts-details-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/details-[id].vue'),
        /* no children */
      },
  _definePage_default_17
  ),
  _mergeRouteRecord(
      {
        path: 'list',
        name: 'contacts-list',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/contacts/list.vue'),
        /* no children */
      },
  _definePage_default_18
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/dashboard.vue'),
    /* no children */
  },
  _definePage_default_19
  ),
  {
    path: '/demo-account',
    /* internal name: 'demo-account' */
    /* no component */
    children: [
      {
        path: '',
        name: 'demo-account',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/demo-account/index.vue'),
        /* no children */
      },
      {
        path: ':masterAccountId',
        name: 'demo-account-master-account-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/demo-account/[masterAccountId].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/helpdesk',
    /* internal name: 'helpdesk' */
    /* no component */
    children: [
      {
        path: '',
        name: 'helpdesk',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/index.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'assignment_rules',
        name: 'helpdesk-assignment-rules',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/assignment_rules.vue'),
        /* no children */
      },
  _definePage_default_20
  ),
  _mergeRouteRecord(
      {
        path: 'forms',
        name: 'helpdesk-forms',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/forms.vue'),
        /* no children */
      },
  _definePage_default_21
  ),
  _mergeRouteRecord(
      {
        path: 'list',
        name: 'helpdesk-list',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/list.vue'),
        /* no children */
      },
  _definePage_default_22
  ),
      {
        path: 'ticket',
        /* internal name: 'helpdesk-ticket' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'helpdesk-ticket-id',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/ticket/[id].vue'),
            /* no children */
          },
  _definePage_default_23
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'tickets',
        name: 'helpdesk-tickets',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/helpdesk/tickets.vue'),
        /* no children */
      },
  _definePage_default_24
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/households',
    name: 'households',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/households.vue'),
    /* no children */
  },
  _definePage_default_25
  ),
  {
    path: '/insurance-license-types',
    /* internal name: 'insurance-license-types' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'insurance-license-types',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/insurance-license-types/index.vue'),
        /* no children */
      },
  _definePage_default_26
  )
    ],
  },
  {
    path: '/investment-companies',
    /* internal name: 'investment-companies' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'investment-companies-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/[id].vue'),
        /* no children */
      },
  _definePage_default_27
  ),
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'investment-companies-create',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/create.vue'),
        /* no children */
      },
  _definePage_default_28
  ),
      {
        path: 'list',
        /* internal name: 'investment-companies-list' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'investment-companies-list',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/list/index.vue'),
            /* no children */
          },
  _definePage_default_29
  )
        ],
      },
      {
        path: 'view',
        /* internal name: 'investment-companies-view' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'investment-companies-view-id',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/investment-companies/view/[id].vue'),
            /* no children */
          },
  _definePage_default_30
  )
        ],
      }
    ],
  },
  {
    path: '/license-types',
    /* internal name: 'license-types' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'license-types',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/license-types/index.vue'),
        /* no children */
      },
  _definePage_default_31
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/login.vue'),
    /* no children */
  },
  _definePage_default_32
  ),
  {
    path: '/organizations',
    /* internal name: 'organizations' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'organizations-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/[id].vue'),
        /* no children */
      },
  _definePage_default_33
  ),
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'organizations-create',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/create.vue'),
        /* no children */
      },
  _definePage_default_34
  ),
      {
        path: 'list',
        /* internal name: 'organizations-list' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'organizations-list',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/list/index.vue'),
            /* no children */
          },
  _definePage_default_35
  )
        ],
      },
      {
        path: 'view',
        /* internal name: 'organizations-view' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'organizations-view-id',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations/view/[id].vue'),
            /* no children */
          },
  _definePage_default_36
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/organizations-deprecated',
    name: 'organizations-deprecated',
    component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/organizations-deprecated.vue'),
    /* no children */
  },
  _definePage_default_37
  ),
  {
    path: '/representatives',
    /* internal name: 'representatives' */
    /* no component */
    children: [
      {
        path: 'list',
        /* internal name: 'representatives-list' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'representatives-list',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/representatives/list/index.vue'),
            /* no children */
          },
  _definePage_default_38
  )
        ],
      }
    ],
  },
  {
    path: '/roles-management',
    /* internal name: 'roles-management' */
    /* no component */
    children: [
      {
        path: 'list',
        /* internal name: 'roles-management-list' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'roles-management-list',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/roles-management/list/index.vue'),
            /* no children */
          },
  _definePage_default_39
  )
        ],
      }
    ],
  },
  {
    path: '/system-settings',
    /* internal name: 'system-settings' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':tab',
        name: 'system-settings-tab',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/system-settings/[tab].vue'),
        /* no children */
      },
  _definePage_default_40
  )
    ],
  },
  {
    path: '/teams',
    /* internal name: 'teams' */
    /* no component */
    children: [
      {
        path: 'list',
        /* internal name: 'teams-list' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'teams-list',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/teams/list/index.vue'),
            /* no children */
          },
  _definePage_default_41
  )
        ],
      },
      {
        path: 'view',
        /* internal name: 'teams-view' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'teams-view-id',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/teams/view/[id].vue'),
            /* no children */
          },
  _definePage_default_42
  )
        ],
      }
    ],
  },
  {
    path: '/user-invites',
    /* internal name: 'user-invites' */
    /* no component */
    children: [
      {
        path: 'list',
        /* internal name: 'user-invites-list' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'user-invites-list',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-invites/list/index.vue'),
            /* no children */
          },
  _definePage_default_43
  )
        ],
      }
    ],
  },
  {
    path: '/user-management',
    /* internal name: 'user-management' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'user-management-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/[id].vue'),
        /* no children */
      },
  _definePage_default_44
  ),
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'user-management-create',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/create.vue'),
        /* no children */
      },
  _definePage_default_45
  ),
      {
        path: 'list',
        /* internal name: 'user-management-list' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'user-management-list',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/list/index.vue'),
            /* no children */
          },
  _definePage_default_46
  )
        ],
      },
      {
        path: 'view',
        /* internal name: 'user-management-view' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'user-management-view-id',
            component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/user-management/view/[id].vue'),
            /* no children */
          },
  _definePage_default_47
  )
        ],
      }
    ],
  },
  {
    path: '/workflows',
    /* internal name: 'workflows' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'workflows-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/[id].vue'),
        /* no children */
      },
  _definePage_default_48
  ),
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'workflows-create',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/create.vue'),
        /* no children */
      },
  _definePage_default_49
  ),
  _mergeRouteRecord(
      {
        path: 'details-:id',
        name: 'workflows-details-id',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/details-[id].vue'),
        /* no children */
      },
  _definePage_default_50
  ),
  _mergeRouteRecord(
      {
        path: 'list',
        name: 'workflows-list',
        component: () => import('/Users/ameerhamza/Documents/projects/Aymen_Ramlaoui/kwc/resources/js/pages/workflows/list.vue'),
        /* no children */
      },
  _definePage_default_51
  )
    ],
  }
]
