<script setup>
import UserInviteForm from "@/components/user-invites/UserInviteForm.vue";
import UserInvitesDataTable from "@/components/user-invites/UserInvitesDataTable.vue";
import UserInviteFilters from "@/components/user-invites/UserInviteFilters.vue";
import { onMounted, ref, watch, computed } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";

const $loading = useLoading(loaderData);

const errors = ref([]);
const headers = ref([
  {
    title: "User",
    key: "first_name",
  },
  {
    title: "Role",
    key: "role",
    sortable: true,
  },
  {
    title: "Invited By",
    key: "invited_by",
    sortable: true,
  },
  {
    title: "Invited On",
    key: "created_at",
    sortable: true,
  },
  {
    title: "Status",
    key: "is_accepted",
    sortable: true,
  },
  {
    title: "Expires In",
    key: "expires_at",
    sortable: true,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);

const userInvites = ref({});
const openModal = ref(false);
const deleteDialog = ref(false);
const isCanSeeCreateBtn = ref(false);
const modeModal = ref("create");
const filterInfo = ref({});
const editedItem = ref({});
const allowed_roles = ref([]);
const allowed_roles_for_edit_delete = ref([]);

const status = ref([
  {
    title: "Accepted",
    value: 1,
  },
  {
    title: "Pending",
    value: 0,
  },
]);

const roles = ref([]);

onMounted(async () => {
  // allowed roles for current user
  axios
    .get("users/allowed/roles_list")
    .then(async (response) => {
      if (response.status == "success") {
        allowed_roles_for_edit_delete.value = response.data.roles;
        allowed_roles.value = Object.keys(response.data.roles).map((key) => ({
          key,
          value: response.data.roles[key],
        }));
        
        // Set up roles for filtering
        roles.value = Object.entries(response.data.roles).map(
          ([index, value]) => {
            return {
              value: index,
              title: value,
            }
          }
        );
        
        await canSeeCreateBtn();
        
        // Set default sort by created_at in descending order
        filterInfo.value = {
          sortBy: 'created_at',
          orderBy: 'desc'
        };
        
        getInvites(filterInfo.value);
      }
    })
    .catch((error) => {})
    .finally(() => {});
});

const getInvites = async (payload = null) => {
  let url = `/user_invites?page=${payload?.page || 1}&per_page=${payload?.perPage || 10}`;
  if (payload?.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload?.sortBy && payload?.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  if (payload?.role) {
    url += `&role=${payload.role}`;
  }
  if (payload?.status === 0 || payload?.status === 1) {
    url += `&is_accepted=${payload.status}`;
  }

  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        userInvites.value = response.data.user_invites;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getInvites(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getInvites(filterInfo.value);
};

const roleChanged = async (payload) => {
  filterInfo.value.role = payload;
  getInvites(filterInfo.value);
};

const statusChanged = async (payload) => {
  filterInfo.value.status = payload;
  getInvites(filterInfo.value);
};

// Page changed from pagination
const refreshInvites = async () => {
  getInvites(filterInfo.value);
};

const deleteItem = (item) => {
  editedItem.value = item;
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete(`/user_invites/${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;

        // refresh the invites list via API
        getInvites(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const resendInvite = async (item) => {
  let loader = $loading.show();
  axios
    .post(`/user_invites/${item.id}/resend`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        getInvites(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      let errorMessage = "An error occurred";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      toast.error(errorMessage, {
        autoClose: 2000,
      });
    })
    .finally(() => {
      loader.hide();
    });
};

const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const canSeeCreateBtn = async () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create User Invite")
  );
  let allowed_roles_available = allowed_roles_for_edit_delete.value.length != 0;
  if (hasPermission && allowed_roles_available) {
    isCanSeeCreateBtn.value = true;
  } else {
    isCanSeeCreateBtn.value = false;
  }
};

defineExpose({
  errors,
  getInvites,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard
    title=""
    class=""
  >
    <VCardText
      class="p-0"
      style="padding: 0 !important;"
    >
      <!-- Users Table Section -->
      <UserInvitesDataTable
        :headers="headers"
        :table-data="userInvites"
        :can-see-create-btn="isCanSeeCreateBtn"
        :allowed_roles="allowed_roles"
        @table-changed="tableChanged"
        @search-value="searchValue"
        @is-dialog-visible="openCreateModel"
        @delete-item="deleteItem"
        @resend-invite="resendInvite"
      />
    </VCardText>
  </VCard>

  <!-- Modals Below -->

  <!-- User Invite Create Modal -->
  <UserInviteForm
    v-model:isDialogVisible="openModal"
    :user-data="editedItem"
    :mode="modeModal"
    :allowed_roles="allowed_roles"
    @refresh-invites="refreshInvites"
  />

  <!-- User Delete Confirmation Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @close-delete="deleteDialog = false"
    @delete-item-confirm="deleteItemConfirm"
  />
</template> 